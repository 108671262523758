export const scoreCardPrescreeningFilterList = [
  {
    text: 'Attended',
    value: "True",
  },
  {
    text: 'Not Attended',
    value: "False",
  },
];

export const scoreCardPrescreeningFilterListScore = [
  {
    text: 'A',
    value: 'A',
  },
  {
    text: 'B',
    value: 'B',
  },
  {
    text: 'C',
    value: 'C',
  },
];

export const scoreCardInterviewFilterList = [
  {
    text: 'Review Pending',
    value: 'REVIEW_PENDING',
  },
  // {
  //   text: 'Review Completed',
  //   value: 'REVIEW_COMPLETED',
  // },
  {
    text: 'Interview Passed',
    value: 'PASSED',
  },
  {
    text: 'Interview Failed',
    value: 'FAILED',
  },
  {
    text: 'Interview On Hold',
    value: 'ONHOLD',
  },
];

export const tableColumn = [
  {
    fieldID: 'id',
    fieldLable: 'Job Reference Id',
    fieldShow: true,
  },
  {
    fieldID: 'JobName',
    fieldLable: 'Job Name',
    fieldShow: true,
  },
  {
    fieldID: 'NoOfOpenings',
    fieldLable: 'No Of Openings',
    fieldShow: true,
  },
  {
    fieldID: 'totalOffered',
    fieldLable: 'Total Offered',
    fieldShow: true,
  },
  {
    fieldID: 'totalHired',
    fieldLable: 'Total Hired',
    fieldShow: true,
  },
  {
    fieldID: 'totalRejected',
    fieldLable: 'Total Rejected',
    fieldShow: true,
  },
  {
    fieldID: 'TotalApplication',
    fieldLable: 'Total Application',
    fieldShow: true,
  },
  {
    fieldID: 'Status',
    fieldLable: 'Status',
    fieldShow: false,
  },
  {
    fieldID: 'Certification',
    fieldLable: 'Certification',
    fieldShow: false,
  },
  {
    fieldID: 'Created',
    fieldLable: 'Created',
    fieldShow: false,
  },
  {
    fieldID: 'ClosurePeriod',
    fieldLable: 'Closure Period',
    fieldShow: false,
  },
  {
    fieldID: 'CreatedBy',
    fieldLable: 'Created By',
    fieldShow: true,
  },
  {
    fieldID: 'CurrencyCode',
    fieldLable: 'Currency Code',
    fieldShow: false,
  },
  {
    fieldID: 'PaymentType',
    fieldLable: 'Payment Type',
    fieldShow: false,
  },
  {
    fieldID: 'Department',
    fieldLable: 'Department',
    fieldShow: false,
  },
  {
    fieldID: 'EducationDetails',
    fieldLable: 'Education Details',
    fieldShow: false,
  },
  {
    fieldID: 'Experience',
    fieldLable: 'Experience',
    fieldShow: false,
  },
  {
    fieldID: 'JobType',
    fieldLable: 'Job Type',
    fieldShow: true,
  },
  {
    fieldID: 'Locations',
    fieldLable: 'Locations',
    fieldShow: false,
  },
  {
    fieldID: 'MaximumCtc',
    fieldLable: 'Maximum ctc',
    fieldShow: false,
    type: 'number',
  },

  {
    fieldID: 'MinimumCtc',
    fieldLable: 'Minimum Ctc',
    fieldShow: false,
    type: 'number',
  },
  {
    fieldID: 'MaximumExperience',
    fieldLable: 'Maximum Experience',
    fieldShow: false,
    type: 'number',
  },
  {
    fieldID: 'MinimumExperience',
    fieldLable: 'Minimum Experience',
    fieldShow: false,
    type: 'number',
  },
  {
    fieldID: 'Posted',
    fieldLable: 'Posted',
    fieldShow: false,
  },
  {
    fieldID: 'RequiredSkill',
    fieldLable: 'Required Skill',
    fieldShow: false,
  },
  {
    fieldID: 'PreferredSkill',
    fieldLable: 'Preferred Skill',
    fieldShow: false,
  },
  {
    fieldID: 'Priority',
    fieldLable: 'Priority',
    fieldShow: false,
  },

  {
    fieldID: 'gender',
    fieldLable: 'Gender',
    fieldShow: false,
  },
  {
    fieldID: 'notice_period',
    fieldLable: 'Notice Period',
    fieldShow: false,
  },
  {
    fieldID: 'visa_required',
    fieldLable: 'Visa Required',
    fieldShow: false,
  },
  {
    fieldID: 'internal_hire',
    fieldLable: 'Internal Hire',
    fieldShow: false,
  },

  {
    fieldID: 'reference_type',
    fieldLable: 'Reference type',
    fieldShow: false,
  },
  {
    fieldID: 'ready_to_relocate',
    fieldLable: 'Ready to relocate',
    fieldShow: false,
  },
  {
    fieldID: 'driving_license_required',
    fieldLable: 'Driving license required',
    fieldShow: false,
  },
  {
    fieldID: 'languages_preferred',
    fieldLable: 'Languages Preferred',
    fieldShow: false,
  },
  {
    fieldID: 'nationality',
    fieldLable: 'Nationality',
    fieldShow: false,
  },
  {
    fieldID: 'recruiters',
    fieldLable: 'Recruiters',
    fieldShow: false,
  },
  {
    fieldID: 'reviewers',
    fieldLable: 'Reviewers',
    fieldShow: false,
  },
  {
    fieldID: 'clients',
    fieldLable: 'Clients',
    fieldShow: false,
  },
  {
    fieldID: 'consultants',
    fieldLable: 'Consultants',
    fieldShow: false,
  },
  {
    fieldID: 'interviews',
    fieldLable: 'Interviews',
    fieldShow: false,
  },
  {
    fieldID: 'interviewers',
    fieldLable: 'Interviewers',
    fieldShow: false,
  },
];

export const AWAITING_QUESTIONNAIRE_COLUMN = [
  {
    fieldID: 'id',
    fieldLable: 'Job Reference Id',
    fieldShow: true,
  },
  {
    fieldID: 'JobName',
    fieldLable: 'Job Name',
    fieldShow: true,
  },
  {
    fieldID: 'levels',
    fieldLable: 'Levels',
    fieldShow: true,
  },
];

export const prescreenColumns = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    width: 200,
    title: 'Phone',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    width: 200,
    title: 'Prescreen Score',
    dataIndex: 'score',
    key: 'score',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: false,
    is_sort: true,
    is_filter: true,
    dynamic_filter: false,
    filters: scoreCardPrescreeningFilterListScore,
    position: 3,
    align: 'center',
  },
  {
    width: 200,
    title: 'Grade',
    dataIndex: 'resume_score',
    key: 'resume_score',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: false,
    is_sort: true,
    is_filter: true,
    dynamic_filter: false,
    filters: scoreCardPrescreeningFilterListScore,
    position: 4,
    align: 'center',
  },
  {
    width: 200,
    title: 'Prescreen Scheduled',
    dataIndex: 'scheduled_date',
    key: 'scheduled_date',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    colType: 'date',
    position: 5,
  },
  {
    width: 200,
    title: 'Prescreen Scheduled Time',
    dataIndex: 'scheduled_time',
    key: 'scheduled_time',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
  },
  {
    width: 200,
    title: 'Attended Date',
    dataIndex: 'attended_date',
    key: 'attended_date',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    colType: 'date',
    position: 7,
  },
  {
    width: 200,
    title: 'Attended Time',
    dataIndex: 'attended_time',
    key: 'attended_time',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 8,
  },
  {
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_sort: true,
    is_search: false,
    is_filter: true,
    dynamic_filter: false,
    filters: scoreCardPrescreeningFilterList,
    position: 9,
  },
];

export const levelsColumns = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    width: 200,
    title: 'Phone',
    dataIndex: 'mobile_number',
    key: 'mobile_number',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    width: 200,
    title: 'Interview Scheduled Date',
    dataIndex: 'interview_scheduled_date',
    key: 'interview_scheduled_date',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    colType: 'date',
    position: 3,
  },
  {
    width: 200,
    title: 'Interview Scheduled Time',
    dataIndex: 'interview_scheduled_time',
    key: 'interview_scheduled_time',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
  },
  {
    width: 200,
    title: 'Attended Date',
    dataIndex: 'interview_attended_date',
    key: 'interview_attended_date',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    colType: 'date',
    position: 5,
  },
  {
    width: 200,
    title: 'Attended Time',
    dataIndex: 'interview_attended_time',
    key: 'interview_attended_time',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
  },
  {
    width: 200,
    title: 'Interviewers',
    dataIndex: 'interviewers',
    key: 'interviewers',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 7,
  },
  {
    width: 200,
    title: 'Times Rescheduled',
    dataIndex: 'rescheduled_count',
    key: 'rescheduled_count',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 8,
    align: 'center',
  },
  {
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_sort: true,
    is_search: false,
    is_filter: true,
    dynamic_filter: false,
    filters: scoreCardInterviewFilterList,
    position: 9,
  },
  {
    width: 200,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    fixed: 'right',
    is_search: false,
    is_sort: false,
    is_filter: false,
    position: 10,
  },
];

export const tableOfferColumn = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    width: 200,
    title: 'Phone',
    dataIndex: 'phone_number',
    key: 'phone_number',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    width: 200,
    title: 'Offered Date',
    dataIndex: 'status_date',
    key: 'status_date',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    colType: 'date',
    position: 2,
  },
  {
    width: 200,
    title: 'Offered Time',
    dataIndex: 'status_time',
    key: 'status_time',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
  },
  {
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
  },
];

export const tableRejectColumn = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
  },
  {
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    width: 200,
    title: 'Phone',
    dataIndex: 'phone_number',
    key: 'phone_number',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
  },
  {
    width: 200,
    title: 'Rejected Date',
    dataIndex: 'status_date',
    key: 'status_date',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    colType: 'date',
    position: 2,
  },
  {
    width: 200,
    title: 'Rejected Time',
    dataIndex: 'status_time',
    key: 'status_time',
    fieldShow: true,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
  },
];
