import React, { useState, useRef, useEffect } from 'react';
import { convertTo24Hour } from './constants';

const TimeInput = ({setSelectedKeys, setIsReset, isReset}) => {
  const [time, setTime] = useState({ hours: '', minutes: '', period: 'AM' });
  const minutesRef = useRef(null);

  useEffect(() => {
    if (time?.hours && time?.minutes) {
        setSelectedKeys([convertTo24Hour(time?.hours+":"+time?.minutes+" "+time?.period)]);
      }
  }, [time]);

  useEffect(() => {
    if (isReset) {
        setTime({ hours: '', minutes: '', period: 'AM' });
    }
  }, [isReset]);

  const handleTimeChange = (field, value, maxLength, nextRef) => {
    // Only allow numbers
    if (!/^\d*$/.test(value)) return;
    // Validate hours (1-12)
    if (field === 'hours' && value.length === 2) {
      const numValue = parseInt(value);
      if (numValue < 1 || numValue > 12) return;
    }
    // Validate minutes (0-59)
    if (field === 'minutes' && value.length === 2) {
      const numValue = parseInt(value);
      if (numValue < 0 || numValue > 59) return;
    }
    // Update the field value
    setTime(prev => ({ ...prev, [field]: value }));
    // Auto-advance to next field if max length reached
    if (value.length === maxLength && nextRef) {
      nextRef.current.focus();
    }
  };

  const handlePeriodChange = (newPeriod) => {
    setTime(prev => ({ ...prev, period: newPeriod }));
  };

  return (
    <div className="flex items-center space-x-2 mb-2">
      <input
        type="text"
        value={time.hours}
        onChange={(e) => handleTimeChange('hours', e.target.value, 2, minutesRef)}
        placeholder="HH"
        maxLength={2}
        className="w-20 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
        aria-label="Hours"
      />
      <span className="text-gray-500">:</span>
      <input
        ref={minutesRef}
        type="text"
        value={time.minutes}
        onChange={(e) => handleTimeChange('minutes', e.target.value, 2, null)}
        placeholder="MM"
        maxLength={2}
        className="w-20 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
        aria-label="Minutes"
      />
      <select
        value={time.period}
        onChange={(e) => handlePeriodChange(e.target.value)}
        className="w-28 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center bg-white"
        aria-label="AM/PM"
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
};

export default TimeInput;