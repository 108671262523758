import axios from 'axios';
import Config from './Config';
import authHeader from './authHeader';

const getDashbordJobCount = (params) => {
  return axios.get(`${Config.API_URL}api/v1/company/dashboard/counts/`, {
    params,
    headers: authHeader(),
  });
};

const getInterviewCount = (params) => {
  return axios.get(
    `${Config.API_URL}api/v1/company/dashboard/interviews/`,
    {
      params,
      headers: authHeader(),
    },
  );
};
const getInterviewerStatus = (params) => {
  return axios.get(
    `${Config.API_URL}api/v1/company/dashboard/interviewer-diagram/`,
    {
      params,
      headers: authHeader(),
    },
  );
};
const getRerutimentDetails = () => {
  return axios.get(
    `${Config.API_URL}api/v1/company/dashboard/recruiters-list/`,
    {
      headers: authHeader(),
    },
  );
};
const getDepartmentWiseVacancies = (params) => {
  return axios.get(
    `${Config.API_URL}api/v1/company/dashboard/department-diagram/`,
    {
      params,
      headers: authHeader(),
    },
  );
};
const getDashboardJobDetails = (filterData, searchData, limit, offset) => {
  console.log('filterDatas', filterData);
  console.log('search', searchData);
  return axios.get(
    `${Config.API_URL}api/v1/company/dashboard/active-jobs/?search=${searchData ? searchData : ''}&status=${filterData ? JSON.stringify(filterData) : ''}&limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  );
};
const getDashboardPipelineDetails = (jobDetails) => {
  console.log('jobDetails', jobDetails);
  return axios.get(
    `${Config.API_URL}api/v1/company/dashboard/active-jobs-statistics/?job_id=${jobDetails?.jobId}&duration=${jobDetails?.selectedMonth}`,
    {
      headers: authHeader(),
    },
  );
};
const createDashboardDragAndDrag = (data) => {
  console.log('Depart', data);

  return axios.post(
    `${Config.API_URL}api/v1/accounts/dashboard-preference-settings/`,
    data,
    {
      headers: authHeader(),
    },
  );
};

const dashboardService = {
  getDashbordJobCount,
  getInterviewCount,
  getRerutimentDetails,
  getDepartmentWiseVacancies,
  getDashboardJobDetails,
  getDashboardPipelineDetails,
  createDashboardDragAndDrag,
  getInterviewerStatus,
};

export default dashboardService;
