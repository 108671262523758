import { useMutation, useQuery } from '@tanstack/react-query';
import InhouseTestService from 'Services/InhouseTestService';
import interviewService from 'Services/interviewService';

/* 
  to get ai review page header data
*/
const useGetFeedbackHeaderData = (config) => {
  const {
    params,
    onSuccess = () => null,
    enabled = true,
    queryKeyName = 'feedbackHeaderData',
    ...queryConfigs
  } = config;
  const queryKey = [params?.interviewId];
  const query = useQuery({
    queryKey: [queryKeyName, queryKey],
    queryFn: async () => {
      const headerData = await interviewService.getAIInterviewDetails(
        params.interviewId,
      );
      return headerData?.data?.data;
    },
    enabled,
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  API for showing data in annotations tab
*/
const useGetAiAssessmentQuestionAnswers = (config) => {
  const {
    params,
    onSuccess = () => null,
    enabled = true,
    queryKeyName = 'aiAssessmentQuestionAnswers',
    ...queryConfigs
  } = config;
  const queryKey = [params?.interviewId];
  const query = useQuery({
    queryKey: [queryKeyName, queryKey],
    queryFn: async () => {
      const headerData =
        await interviewService.getAiAssessmentQuestionAnswers(
          params.interviewId,
        );
      return headerData?.data?.data;
    },
    enabled,
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  API for getting infraction images to show in modal
*/
const useGetInfractionImages = (config) => {
  const {
    params,
    onSuccess = () => null,
    enabled = true,
    queryKeyName = 'infractionImages',
    ...queryConfigs
  } = config;
  const queryKey = [params?.interviewId];
  const query = useQuery({
    queryKey: [queryKeyName, queryKey],
    queryFn: async () => {
      console.log('params', params);
      const headerData =
        await interviewService.getInfractionImages(params);
      return headerData?.data?.data;
    },
    enabled,
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

// const useGetInterviewList = (config) => {
//   const {
//     params,
//     onSuccess = () => null,
//     onError = () => null,
//     ...queryConfigs
//   } = config;
//   const queryKey = [
//     params?.status,
//     params?.pageSize,
//     params?.page,
//     params?.offset,
//     params?.search,
//     params?.searchInputFinal,
//   ];
//   let filtervalue = params?.searchInputFinal;
//   const query = useQuery({
//     queryKey: ['getInterviewList', queryKey],
//     queryFn: async () => {
//       let paginationParams;
//       if (params?.search) {
//         paginationParams = {
//           status: params?.status,
//           page: params?.page,
//           pageSize: params?.pageSize,
//           offset: params?.offset,
//           limit: params.pageSize,
//           search: params.search,
//         };
//       } else {
//         paginationParams = {
//           status: params?.status,
//           page: params?.page,
//           pageSize: params?.pageSize,
//           offset: params?.offset,
//           limit: params.pageSize,
//         };
//       }

//       const details = await interviewService.getInterviewList(
//         paginationParams,
//         filtervalue,
//       );
//       return details?.data?.data;
//     },
//     onSuccess,
//     onError,
//     refetchOnWindowFocus: false,
//     ...queryConfigs,
//   });
//   return query;
// };
const useGetInterviewList = ({ params = {}, filter = '', ...rest }) => {
  // const filterPhrases = filter?.split('=');
  // let filterKey;
  // let term;
  // if (Boolean(filter)) {
  //   [filterKey, term] = filterPhrases;
  // }

  const query = useQuery({
    queryKey: [
      ' getInterviewList',
      params?.limit,
      params?.offset,
      params?.status,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        status: params?.status,
        limit: params?.limit,
        offset: params?.offset,
        // ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
        ...(Boolean(rest?.view_more_candidates) && {
          view_more_candidates: rest?.view_more_candidates,
        }),
      };

      if (filter?.length) {
        filter?.map((item) => {
          const filterPhrases = item?.split('=');
          let filterKey;
          let term;
          // ["DEFAULT","FAILED","COMPLETED"]
          if (Boolean(filter)) {
            [filterKey, term] = filterPhrases;
            console.log(term, 'updatedTermupdatedTerm');
            // const updatedTerm = JSON.parse(term).includes('pending_for_review')
            // ? [
            //     ...JSON.parse(term).filter((item) => item !== 'pending_for_review'),
            //     'DEFAULT',
            //     'FAILED',
            //     'COMPLETED',
            //   ]
            // : term;

            let parsedTerm;
            try {
              parsedTerm = JSON.parse(term);
            } catch {
              parsedTerm = term;
            }

            const updatedTerm =
              Array.isArray(parsedTerm) &&
              parsedTerm.includes('pending_for_review')
                ? JSON.stringify([
                    ...parsedTerm.filter(
                      (item) => item !== 'pending_for_review',
                    ),
                    'DEFAULT',
                    'FAILED',
                    'COMPLETED',
                  ])
                : term;

            queryParams[filterKey] = updatedTerm;
          }
        });
      }
      console.log(
        queryParams,
        'queryParamsqueryParamsqueryParams',
        rest?.view_more_candidates,
      );
      const response =
        await interviewService.getInterviewList(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};
/* 
  Fetches the chosen interview information.
*/
export const GET_INTERVIEW_INFO = 'GET_INTERVIEW_INFO';
const useGetCurrentInterviewInformation = (config) => {
  const { params, ...rest } = config;
  const query = useQuery({
    queryKey: [GET_INTERVIEW_INFO, params?.interview],
    queryFn: async () => {
      const details =
        await interviewService.getCurrentInterviewInfo(params);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  API for getting infraction images to show in modal
*/
export const useUpdateInterviewInformation = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ params, body }) => {
      const details = await interviewService.updateInterviewInformation(
        params,
        body,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  Fetches the existing scheduled interview details.
  Currently used for prefilling schedule interview forms.
*/
export const GET_SCHEDULE_DETAILS = 'GET_SCHEDULE_DETAILS';
export const useGetScheduleDetails = ({ interviewId, ...rest }) => {
  const query = useQuery({
    queryKey: [GET_SCHEDULE_DETAILS, interviewId],
    queryFn: async () => {
      const response =
        await interviewService.getScheduleDetails(interviewId);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  Fetches the chosen interview information.
*/
export const GET_CANDIDATE_INTERVIEW_HISTORY =
  'GET_CANDIDATE_INTERVIEW_HISTORY';
const useGetCandidateInterviewHistory = (config) => {
  const { interviewId, ...rest } = config;
  const query = useQuery({
    queryKey: [GET_CANDIDATE_INTERVIEW_HISTORY, interviewId],
    queryFn: async () => {
      const response =
        await interviewService.getInterviewHistory(interviewId);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  API for getting infraction images to show in modal
*/
const useSubmitInterviewerFeedback = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const details =
        await interviewService.submitInterviewFeedback(params);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};

/* 
  API for getting infraction images to show in modal
*/
const useCancelScheduleInterview = (config) => {
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details =
        await interviewService.cancelScheduledInterview(data);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  API for flagging an interview with a no-show candidate
*/
export const useSetNoShowInterview = (config) => {
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details = await interviewService.setNoShowInterview(
        data,
        data?.id,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

export const GET_COMPLETED_INTERVIEW_FEEDBACK =
  'GET_COMPLETED_INTERVIEW_FEEDBACK';
const useFinalStatus = (config) => {
  const { params, ...rest } = config;
  const id = params?.id;
  const query = useQuery({
    queryKey: [GET_COMPLETED_INTERVIEW_FEEDBACK, id],
    queryFn: async () => {
      const details = await interviewService.getInterviewAnswerById(id);
      return details.data.data;
    },
    ...rest,
  });
  return query;
};

const useCompanyDetails = () => {
  const query = useQuery({
    queryKey: ['companyDeatils'],
    queryFn: async () => {
      const details = await interviewService.getCompanyDetails();
      return details.data.data;
    },
  });
  return query;
};

const useGetInterviewInformation = (config) => {
  const query = useQuery({
    queryKey: ['interviewInfo'],
    queryFn: async () => {
      console.log('interviewId', config);
      const details = await InhouseTestService.getInterviewInfo(
        config.paramsData,
      );
      return details.data.data;
    },
  });
  return query;
};

const useGetFinalScreenData = (config) => {
  const query = useQuery({
    queryKey: ['submit_Interview'],
    queryFn: async () => {
      console.log('interviewId', config);
      const details = await InhouseTestService.submitAllInterview(
        config.paramsData,
      );
      return details.data.data;
    },
  });
  return query;
};
const useGetBigSelectionList = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['selectionList'],
    queryFn: async () => {
      const details = await interviewService.getBig5SelectionList();
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

const useCreateBigFiveSelection = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      console.log('testdata', params);
      const { data, interviewId } = params;
      const details = await interviewService.addBigFiveSelection(
        data,
        interviewId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
export {
  useGetFeedbackHeaderData,
  useGetAiAssessmentQuestionAnswers,
  useGetInfractionImages,
  useSubmitInterviewerFeedback,
  useGetInterviewList,
  useCancelScheduleInterview,
  useFinalStatus,
  useCompanyDetails,
  useGetCandidateInterviewHistory,
  useGetCurrentInterviewInformation,
  useGetInterviewInformation,
  useGetFinalScreenData,
  useGetBigSelectionList,
  useCreateBigFiveSelection,
};
