import { useMutation, useQuery } from '@tanstack/react-query';
import dashboardService from 'Services/dashboardService';
import { DEFAULT_QUERY_ARGS } from 'Utilities/constants';

const useDashboardCount = (config) => {
  const { params = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['dashboard', params?.startDate, params?.endDate],
    queryFn: async () => {
      const queryParams = {
        startDate: params?.startDate,
        endtDate: params?.endDate,
      };
      const details =
        await dashboardService.getDashbordJobCount(queryParams);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

const useDashboardInterviewCount = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: [
      'interviewCounts',
      paramsData?.dashboardInterviewDate,
      paramsData.interviewer_id,
    ],
    queryFn: async () => {
      const queryParams = {
        filter_on: paramsData?.dashboardInterviewDate,
        interviewer_id: paramsData.interviewer_id,
      };
      const details =
        await dashboardService.getInterviewCount(queryParams);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

const useDashboardRecuritmentDetails = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['recruitment'],
    queryFn: async () => {
      const details = await dashboardService.getRerutimentDetails();
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useDashboardDepartmentDetails = (config) => {
  const { params = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: [
      'deparmentWiseVacancies',
      params.limit,
      params.offset,
      params?.duration,
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params.limit,
        offset: params.offset,
        duration: params?.duration,
      };
      const details =
        await dashboardService.getDepartmentWiseVacancies(queryParams);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

const useDashboardJobDetails = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: [
      'dashboardJobDetails',
      paramsData?.dashboardFilterdata,
      paramsData?.search,
      paramsData?.limit,
      paramsData?.offset,
    ],
    queryFn: async () => {
      const details = await dashboardService.getDashboardJobDetails(
        paramsData?.dashboardFilterdata,
        paramsData?.search,
        paramsData?.limit,
        paramsData?.offset,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useDashboardPipeLineDetails = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['dashboardPipleline', paramsData?.dashboardJobDate],
    queryFn: async () => {
      const details = await dashboardService.getDashboardPipelineDetails(
        paramsData?.dashboardJobDate,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useDragAndDrop = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const details =
        await dashboardService.createDashboardDragAndDrag(params);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useDashboardInterviewerStatus = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: [
      'interviewerStatus',
      paramsData?.dashboardInterviewDate,
      paramsData.interviewer_id,
    ],
    queryFn: async () => {
      const queryParams = {
        filter_on: paramsData?.dashboardInterviewDate,
        interviewer_id: paramsData.interviewer_id,
      };
      const details =
        await dashboardService.getInterviewerStatus(queryParams);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
export {
  useDashboardCount,
  useDashboardInterviewCount,
  useDashboardRecuritmentDetails,
  useDashboardDepartmentDetails,
  useDashboardJobDetails,
  useDashboardPipeLineDetails,
  useDragAndDrop,
  useDashboardInterviewerStatus,
};
