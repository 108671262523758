import { useEffect, useMemo, useRef, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { Tooltip } from 'antd';

import { useLayoutStore } from 'Store/LayoutStore';
import CreateRegistration from './CreateRegistration';
import { PERMISSION_TYPES } from 'Utilities/constants';
import RegistartionDetails from './RegistartionDetails';
import { useAuthentication } from 'ServiceHooks/authHooks';
import Empty from 'Components/CommonComponents/Empty/Empty';
import Button from 'Components/CommonComponents/Button/Button';
import AntdTable from 'Components/CommonComponents/AntdTable/AntdTable';
import { useGetRegistrationList } from 'ServiceHooks/registrationHooks';
import ActionsDropdown from 'Components/CommonComponents/ActionsDropdown';
import {
  add_company,
  edit_active,
  TableActionsIcon,
  add_company_active,
} from 'Components/CommonComponents/Icons';

const currentStatus = 'REGISTRATIONLIST';

export default function RegistrationManagementComp() {
  const [listView, setListView] = useState('true');
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [compnyId, setCompnyId] = useState(false);

  const [gridColumn, setGridColumn] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [sorting, setSorting] = useState('');
  const [hoveredRow, setHoveredRow] = useState(null);
  const [rowInstance, setRowInstance] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [offset, setOffset] = useState(0);
  const { fullPermissionInfo } = useAuthentication();
  // const screenRef = useRef(null);
  const [screenRef, setScreenRef] = useState(null)

  const isAddPermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.ADD_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.ADD_COMPANY_REGISTRATION] === true;

  const isEditPermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.EDIT_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.EDIT_COMPANY_REGISTRATION] ===
      true;

  //Unimplemented feature
  const isDeletePermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.DELETE_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.DELETE_COMPANY_REGISTRATION] ===
      true;

  const isViewPermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.VIEW_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.VIEW_COMPANY_REGISTRATION] ===
      true;

  const {
    data: registrationList,
    isLoading: isLoadingrRgistrationList,
    isFetching: isRegistrationListtFetching,
  } = useGetRegistrationList({
    filter,
    sorting,
    params: {
      search,
      limit: pageSize,
      offset: offset,
      order_by: sorting,
    },
  });

  const handleEdit = (id) => {
    setCompnyId(id);
    setIsEdit(true);
  };
  const handleAdd = () => {
    setIsAdd(true);
    setCompnyId('');
  };

  const { column_preferece, setGridColumnPreference } = useLayoutStore(
    (state) => ({
      column_preferece: state.column_preferece,
      setGridColumnPreference: state.setGridColumnPreference,
    }),
  );

  useEffect(() => {
    if (
      !isEmpty(column_preferece?.REGISTRATIONPREFERENCE[currentStatus])
    ) {
      setGridColumn(
        column_preferece?.REGISTRATIONPREFERENCE[currentStatus],
      );
    }
  }, [column_preferece]);

  const grid_columns = useMemo(() => {
    const updatedColumns = gridColumn?.map((column) => {
      let updatedColumn = { ...column };

      if (column?.dataIndex === 'actions') {
        updatedColumn = {
          ...updatedColumn,
          width: 80,
          fixed: 'right',
          title: TableActionsIcon,
          render: (_, record, index) => {
            if (index === hoveredRow) {
              return (
                <ActionsDropdown
                  containerType={() => screenRef?.current}
                  items={[
                    {
                      key: '0',
                      label: (
                        <Tooltip
                          title="Edit Registration"
                          placement="leftBottom"
                        >
                          <Button type="text">
                            <img
                              alt="edit"
                              className="min-w-[1rem]"
                              src={edit_active}
                            />
                          </Button>
                        </Tooltip>
                      ),
                      onClick: () => handleEdit(record.id),
                    },
                  ]}
                />
              );
            } else {
              return null;
            }
          },
        };
      }
      // Add additional conditional rendering for other columns here if needed

      return updatedColumn;
    });

    return updatedColumns;
  }, [gridColumn, hoveredRow]);

  const handleSelectedRow = (record) => {
    setRowInstance(record);
  };
  const tableList = () => {
    return (
      <div className="mt-spacing1 w-full max-h-[calc(90vh-9.5rem)] overflow-y-auto scrollbar-none">
        <AntdTable
          screenRef={screenRef}
          setScreenRef={setScreenRef}
          grid_columns={grid_columns}
          setGridColumn={setGridColumn}
          data={registrationList?.results}
          totalItems={registrationList?.count}
          table_name={currentStatus}
          preference="REGISTRATIONPREFERENCE"
          setGridColumnPreference={setGridColumnPreference}
          column_preferece={column_preferece}
          setPageSize={setPageSize}
          setHoveredRow={setHoveredRow}
          setOffset={setOffset}
          pageSize={pageSize}
          offset={offset}
          setSearch={setSearch}
          search={search}
          setFilter={setFilter}
          setSorting={setSorting}
          loading={
            isLoadingrRgistrationList || isRegistrationListtFetching
          }
          handleRowClick={handleSelectedRow}
        />
      </div>
    );
  };

  const truncateStrHandler = (str) => {
    return str.length > 45 ? str.substring(0, 35) + '...' : str;
  };

  const registeredCompanyCardView = (data) => {
    return (
      <div>
        <div className="p-4 m-3 w-36 lg:w-72 overflow-hidden border-[0.1px] border-gray-300 hover:shadow-[0px_2px_2px_2px_#00000024] rounded-[10px] justify-center flex-col md:flex-row lg:flex-row cursor-pointer transition ease-in-out delay-90 hover:-translate-y-1 hover:scale-80 duration-300">
          <div className="flex flex-row flex-wrap justify-center pl-2 border-b-gray-200 border-b">
            <div>
              <p className="text-xs sm:text-sm font-semibold mb-1">
                {truncateStrHandler(data.name)}
              </p>
            </div>
          </div>
          <div class="px-6 pt-4 pb-2 text-center">
            <span
              class="inline-block border-solid border-2 border-sky-500 rounded-full px-3 py-1 text-sm font-semibold mr-3 mb-2 transition ease-in-out delay-90 hover:text-black hover:bg-slate-200 hover:-translate-y-1 hover:scale-80 duration-300"
              title="Edit"
            >
              <EditOutlined
                onClick={() => handleEdit(data.id)}
                disabled={!isEditPermissionAvailable}
                // onClick={() => handleEdit(data.id)}
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {isAddPermissionAvailable && !isAdd && !isEdit && (
          <div className="flex flex-wrap justify-end items-center pl-1 mb-6 w-full h-fit">
            <Tooltip title="Add Company" placement="bottom">
              <Button
                type="text"
                className="h-auto"
                onClick={() => {
                  handleAdd();
                }}
              >
                <img
                  className="cursor-pointer min-h-[2.25rem]"
                  src={isAdd ? add_company_active : add_company}
                  alt="Add Company"
                />
              </Button>
            </Tooltip>
          </div>
        )}
        <div className="flex flex-wrap">
          {isAdd === true ? (
            <CreateRegistration
              close={() => setIsAdd(false)}
              isAdd={isAdd}
              editClose={() => setIsEdit(false)}
              isEdit={isEdit}
              title={'Edit Company Registration'}
            />
          ) : isEdit === true ? (
            <RegistartionDetails
              compnyId={compnyId}
              close={() => setIsEdit(false)}
            />
          ) : listView === 'true' ? (
            <>
              {isViewPermissionAvailable ? (
                tableList()
              ) : (
                <div className="w-full m-auto">
                  <Empty isError description={'Unauthorized'} />
                </div>
              )}
            </>
          ) : (
            registrationList?.results?.map((items) => {
              return registeredCompanyCardView(items);
            })
          )}
        </div>
      </div>
    </>
  );
}
