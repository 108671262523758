import { useEffect, useMemo, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneInput from 'react-phone-input-2';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import { toast } from 'react-hot-toast';
import { useLocalStorage } from 'react-use';

import fileUpload from 'Assets/icons/fileUpload.svg';
import settingService from 'Services/settingsService';
import companyRegistration from 'Services/companyRegistration';
import Button from 'Components/CommonComponents/Button/Button';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import DatePicker from 'Components/CommonComponents/ReactHooKForm/DatePicker/DatePicker';
import RadioGroup from 'Components/CommonComponents/ReactHooKForm/RadioGroup/RadioGroup';
import { useCreateCompanyRegistration } from 'ServiceHooks/registrationHooks';
import 'react-phone-input-2/lib/style.css';
import {
  CURRENCY_CODES,
  DEFAULT_ERROR_MESSAGE,
} from 'Utilities/constants';
import CountryCodeInput from 'Components/CommonComponents/Input/CountryCodeInput';

const CreateRegistration = (props) => {
  const navigate = useNavigate();
  const [userSettings] = useLocalStorage('user');

  const hiddenFileInput = useRef(null);
  const [fileName, setFileName] = useState();
  const [uploadedImage, setUpladedImage] = useState();
  const [isActiveFile, setActiveFile] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [isCountryLayout, setCountryLayout] = useState(false);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [phoneCode, setPhonecode] = useState();
  const [lisensingPlan, setLisensingPlan] = useState();
  const [formError, setFormError] = useState();
  const [isCompanyLayout, setCompanyLayout] = useState(false);
  const [companyCode, setcompanyCode] = useState();
  const [responsedata, setResponseData] = useState();

  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      licensing: false,
    },
  });

  const SOURCE_OPTIONS = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];
  console.log('erroesform', errors);
  const selectedLisensecing = watch('licensing');

  const getRegistrationInfo = async () => {
    await companyRegistration
      .getRegistartioData(props.companyId)
      .then((response) => {
        setResponseData(response?.data?.data);
        companyDetails(response?.data?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getLisensePlan = async () => {
    await companyRegistration
      .getLisensePlan()
      .then((response) => {
        setLisensingPlan(response?.data?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const industryOptions = [
    { value: 'CONSULTANCY', label: 'Consultancy' },
    { value: 'COMPANY', label: 'Company' },
  ];

  const searchOptions = useMemo(() => {
    if (isEmpty(lisensingPlan?.results)) return [];

    console.log('testpy', lisensingPlan?.results);
    const options = lisensingPlan?.results.map((item) => {
      return {
        label: item.title,
        value: item.id,
      };
    });
    return options;
  }, [lisensingPlan]);

  const companyDetails = (data) => {
    setValue('name', data?.name);
    setValue('primary_address', data?.primary_address);
    setValue('about_the_company', data?.about_the_company);
    setValue('domain_name', data?.domain_name);
    setValue('zip_code', data?.zip_code);
    setValue('email', data?.email);
    setValue('mobile_number', data?.mobile_number);
    setValue('mobile_country_code', data?.mobile_country_code);
    setValue('first_name', data?.first_name);
    setValue('last_name', data?.last_name);
    setValue('contact_person_email', data?.contact_person_email);
    setValue('about_the_company', data?.about_the_company);
    setValue('facebook', data?.facebook);
    setValue('twitter', data?.twitter);
    setValue('instagram', data?.instagram);
    setValue('location', data?.location);
    setValue('linkedin', data?.linkedin);
    setValue('company_domain', data?.company_domain);
    setValue('zip_code', data?.zip_code);
    setValue('company_email', data?.company_email);
    setValue('subscription', data.subscription);
    setValue('industry', data.industry);
    setValue('licensing', data.licensing);
    setValue('company_contact_number', data.company_contact_number);
    setValue(
      'company_contact_country_code',
      data.company_contact_country_code,
    );
    setValue('logo', data.logo);
    setValue('ctc_currency_code', data.ctc_currency_code);
  };

  useEffect(() => {
    getRegistrationInfo();
    getLisensePlan();
  }, [props.companyId]);

  const handelInputChange = (onChange, e) => {
    onChange(e);
    console.log('testwrapper', e);
    if (e.target.value !== '') {
      var test = Number(e.target.value);
      var test1 = Number.isInteger(test);
      if (test1) {
        setCountryLayout(true);
        setPhonecode('+91');
      } else {
        setCountryLayout(false);
        setPhonecode('');
      }
    } else {
      setCountryLayout(false);
      setPhonecode('');
    }
  };
  const onCancel = () => {
    if (!props.companyId) {
      props.close();
    } else {
      props.close();
    }
  };
  const handleBack = () => {
    props.close();
  };
  const handelChangePhone = (e) => {
    var value = `+${e}`;
    setPhonecode(value);
  };

  const handelPhone = (e) => {
    var value = `+${e}`;
    setPhonecode(value);
  };
  const handelCompanyPhone = (onChange, e) => {
    onChange(e);
    console.log('testwrapper', e);
    if (e.target.value !== '') {
      var test = Number(e.target.value);
      var test1 = Number.isInteger(test);
      if (test1) {
        setCompanyLayout(true);
        setcompanyCode('+91');
      } else {
        setCompanyLayout(false);
        setcompanyCode('');
      }
    } else {
      setCompanyLayout(false);
      setcompanyCode('');
    }
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const { onChange: logoOnChange, ...otherLogoFieldItems } = register(
    'logo',
    {
      required: 'This field is required',
    },
  );
  const handleFile = (e) => {
    console.log('tedte', e.target.files[0]);
    setFileName(e.target.files[0]);
    setUpladedImage(URL.createObjectURL(e.target.files[0]));
    setActiveFile(true);
    //logoOnChange(e)

    setValue('logo', e.target.files[0], {
      shouldDirty: true,
    });
    // await trigger('logo');

    console.log('asdasd');
  };
  const handleSearch = async (serachValue) => {
    await settingService.telePort(serachValue).then((response) => {
      console.log('response', response.data.data.location);
      setSelectedLocation(response.data.data.locations);
    });
  };

  const getSearch = (cities) => {
    console.log('stringscities', cities);
    var location = [];
    cities &&
      cities.map((data, index) =>
        location.push({
          value: index,
          label: data.matching_full_name,
        }),
      );

    return location;
  };
  const sendData = async (data) => {
    console.log('data', data);
    try {
      await companyRegistration.createRegistration(
        data,
        phoneCode,
        companyCode,
      );
      await messageApi.open({
        type: 'success',
        content: 'Company Created',
      });
      props.close();
    } catch (error) {
      const errorsObj = error?.response?.data?.error_info;
      Object.keys(errorsObj).forEach((field) => {
        const messages = errorsObj[field];
        setError(field, {
          type: 'server',
          message: messages.join('. '),
        });
      });
    }
  };
  const updateData = async (data) => {
    console.log('dirtyFields', dirtyFields['mobile_country_code)']);
    let modifiedData = {};
    if (
      (dirtyFields['mobile_number'] && data['mobile_number'] !== '') ||
      dirtyFields['mobile_country_code']
    ) {
      // Add the country code to the mobile number field in the form data
      data['mobile_number'] = data.mobile_number
        ? data.mobile_country_code
          ? `+${data.mobile_country_code}${data.mobile_number}`
          : `+91${data.mobile_number}`
        : '';
    }
    if (
      (dirtyFields['company_contact_number'] &&
        data['company_contact_number'] !== '') ||
      dirtyFields['company_contact_country_code']
    ) {
      data['company_contact_number'] = data.company_contact_number
        ? data.company_contact_country_code
          ? `+${data.company_contact_country_code}${data.company_contact_number}`
          : `+91${data.company_contact_number}`
        : '';
    }
    if (dirtyFields['location'] && data['location'] !== '') {
      data['location'] = data.location.label;
    }

    if (dirtyFields['logo'] && data['logo'] !== '') {
      data['logo'] = data.logo;
    }
    if (
      dirtyFields['ctc_currency_code'] &&
      data['ctc_currency_code'] !== ''
    ) {
      data['ctc_currency_code'] = data.ctc_currency_code.value;
    }

    Object.keys(data).forEach((key) => {
      // Check if the field is modified and it's not empty
      // if (key === 'ctc_currency_code') {
      //   modifiedData[key] = data[key].value;
      // } else if
      if (dirtyFields[key] && data[key] !== '') {
        modifiedData[key] = data[key];
      }
    });
    if (dirtyFields['mobile_country_code']) {
      modifiedData['mobile_number'] = data.mobile_number;
    }
    if (dirtyFields.company_contact_country_code) {
      modifiedData['company_contact_number'] = data.company_contact_number;
    }

    try {
      await companyRegistration.updateRegistration(
        props.companyId,
        modifiedData,
      );
      await messageApi.open({
        type: 'success',
        content: 'Updated Successfully',
      });
      props.close();
    } catch (error) {
      const errorsObj = error?.response?.data?.error_info;
      Object.keys(errorsObj).forEach((field) => {
        const messages = errorsObj[field];
        setError(field, {
          type: 'server',
          message: messages.join('. '),
        });
      });
    }
  };

  const { mutateAsync: createRegistration } = useCreateCompanyRegistration(
    {
      onSuccess: async () => {
        await toast.success('Successfully Registered..!!');
      },
      onError: async (error) => {
        if (message) {
          console.log('dispatched 1');
          console.log('erroe', error?.response?.data?.error_info);
          const errorsObj = error?.response?.data?.error_info;
          Object.keys(errorsObj).forEach((field) => {
            const messages = errorsObj[field];
            setError(field, {
              type: 'server',
              message: messages.join('. '),
            });
          });
        } else {
          toast.error('Something went wrong..!!');
        }
      },
    },
  );
  const onSubmit = async (data) => {
    if (props.companyId) {
      await updateData(data);
    } else {
      await sendData(data);
    }
  };

  return (
    <div className="w-full">
      {!props?.companyId && (
        <button className="flex justify-start" onClick={handleBack}>
          <ArrowBackIcon className="cursor-pointer" />
          <h3 className="text-black tex t-base font-medium mb-4 px-2 cursor-pointer">
            Back
          </h3>
        </button>
      )}

      <h3 className="text-black text-base font-medium mb-4 mx-2">
        {props?.companyId
          ? 'Edit Company Registration'
          : 'Add Company Registration'}
      </h3>
      <form className="customForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-secondaryBgMain rounded-lg flex flex-wrap pb-6 overflow-y-auto max-h-[calc(90vh-11rem)]">
          <div class="px-8 w-full mt-4">
            <div class="flex -mx-2 md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="name"
                  label="Company Name"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="primary_address"
                  label="Company Address"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2  md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Select
                  label="Location Name"
                  placeholder="Search location"
                  style={{ width: '100%' }}
                  //fetching={isJobListFetching}
                  onSearch={(value) => handleSearch(value)}
                  options={selectedLocation?.map((oOption, index) => {
                    return {
                      value: index,
                      label: oOption,
                    };
                  })}
                  rules={{
                    required: 'This field is required',
                  }}
                  name="location"
                  control={control}
                  showSearch
                  allowClear
                  onClear={() => {
                    setValue('job', undefined);
                  }}
                  useDefaultTheme
                />
              </div>
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="zip_code"
                  label="Zip Code"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2  md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="company_email"
                  label="Company Email ID"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="domain_name"
                  label="Company Domain Name "
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2  md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="first_name"
                  label="Company Contact Person First Name"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="last_name"
                  label="Company Contact Person Last Name"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2 md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="email"
                  label="Company Contact Person Email ID"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="md:w-1/2 w-full px-2">
                <CountryCodeInput
                  name="mobile_number"
                  country_code_name="mobile_country_code"
                  country_code_value={getValues('mobile_country_code')}
                  value={watch('mobile_number')}
                  setValue={setValue}
                  label="Mobile number"
                  required={true}
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
                {/* <div style={{ display: 'flex' }}>
                  {isCountryLayout && (
                    <span
                      style={{
                        width: '10%',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                        marginTop: '24px',
                        paddingRight: '45px',
                      }}
                    >
                      <PhoneInput
                        country="In"
                        value="91"
                        inputStyle={{
                          height: '35px',
                          width: '2%',
                          paddingLeft: '0px',
                        }}
                        onChange={(e) => handelChangePhone(e)}
                      />
                    </span>
                  )}
                  <div className="w-full">
                    <Input
                      name="mobile_number"
                      label="Mobile Number"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      onChange={handelInputChange}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2 md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <CountryCodeInput
                  name="company_contact_number"
                  label="Company Contact Number"
                  country_code_name="company_contact_country_code"
                  country_code_value={getValues(
                    'company_contact_country_code',
                  )}
                  value={watch('company_contact_number')}
                  setValue={setValue}
                  required={true}
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
                {/* <div style={{ display: 'flex' }}>
                  {isCompanyLayout && (
                    <span
                      style={{
                        width: '10%',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                        marginTop: '24px',
                        paddingRight: '45px',
                      }}
                    >
                      <PhoneInput
                        country="In"
                        value="91"
                        inputStyle={{
                          height: '35px',
                          width: '2%',
                          paddingLeft: '0px',
                        }}
                        onChange={(e) => handelPhone(e)}
                      />
                    </span>
                  )}
                  <div className="w-full">
                    <Input
                      name="company_contact_number"
                      label="Company Mobile Number"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      onChange={handelCompanyPhone}
                    />
                  </div>
                </div> */}
              </div>
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="linkedin"
                  label="Linked In URL"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2  md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="facebook"
                  label="Facebook URL"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="instagram"
                  label="Instagram URL"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2  md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Input
                  name="about_the_company"
                  label="About the Company"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  className="h-20"
                />
              </div>
              <div className="md:w-1/2 w-full px-2">
                <div className="mt-2">
                  <label className="text-black font-poppins text-xm font-normal leading-normal pb-[12px]">
                    Company Logo
                  </label>
                  <div className="flex">
                    <div className="mt-1 ">
                      <div className="flex flex-wrap rounded-md border border-gray-400 border-opacity-75 px-2  w-[111px] h-[36px]  ">
                        <img
                          src={fileUpload}
                          alt="upload files"
                          className="ml-4 px-2"
                        />
                        <button
                          type="button"
                          className="button-upload"
                          onClick={handleClick}
                        >
                          Upload
                        </button>
                        <input
                          type="file"
                          control={control}
                          {...register('logo', {
                            required: 'This field is required',
                          })}
                          {...otherLogoFieldItems}
                          accept="image/*"
                          onChange={handleFile}
                          ref={hiddenFileInput}
                          style={{ display: 'none' }} // Make the file input element invisible
                        />
                      </div>
                      {errors && errors.logo && (
                        <p className="text-[#ff4d4f] text-sm font-normal text-start mt-2 w-full">
                          {errors.logo.message}
                        </p>
                      )}
                    </div>
                    {
                      isActiveFile && (
                        <div className="flex flex-wrap rounded-md border border-gray-400 border-opacity-75 px-2 mt-1 mx-4">
                          <button
                            type="button"
                            className="button-upload flex flex-wrap "
                          >
                            <img
                              src={uploadedImage}
                              alt="imhg"
                              className="w-7 h-5 mt-2"
                            />
                            <p className="text-body3 mt-3 px-2">
                              {fileName ? fileName?.name : ''}
                            </p>
                          </button>
                        </div>
                      )
                      // <div className="flex flex-wrap mt-2 px-4 mx-4 rounded-md border border-gray-400 border-opacity-75 ">

                      //     <img src={uploadedImage} className="w-7 h-7 mt-2" />
                      //     <p className="text-body3 ml-2 pt-4">{fileName ? fileName?.name : ""}</p>

                      // </div>
                    }
                    {props?.companyId && !isActiveFile && (
                      <div className="flex flex-wrap rounded-md border border-gray-400 border-opacity-75 px-2 mt-1 mx-4">
                        <button
                          type="button"
                          className="button-upload flex flex-wrap "
                        >
                          <img
                            src={responsedata?.logo}
                            alt="imhg"
                            className="w-7 h-5 mt-2"
                          />
                          <p className="text-body3 mt-3 px-2">
                            {fileName ? fileName?.name : ''}
                          </p>
                        </button>
                      </div>
                    )}
                    <p className="text-body3 ml-4 mt-4">
                      SVG, PNG, JPG (MAX. 800x400px)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2 md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <RadioGroup
                  name="licensing"
                  label="Licensed"
                  control={control}
                  // rules={{
                  //   required: 'Please choose an option.',
                  // }}
                  options={SOURCE_OPTIONS}
                />
                {selectedLisensecing && (
                  <div class=" w-full">
                    <div class="flex -mx-2  md:flex-row flex-col">
                      <div className="md:w-1/2 w-full px-2">
                        <Select
                          name="subscription"
                          label=""
                          // onSearch={(text) => setSearch(text)}
                          control={control}
                          onChange={(e, onChange) => {
                            onChange(e.value);
                          }}
                          options={searchOptions}
                          rules={{
                            required: 'This field is required',
                          }}
                          //mode="multiple"
                          //showSearch
                          allowClear
                          placeholder="Choose a plan"
                          // valueLabel="role_name"
                          // optionLabel="role_name"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="md:w-1/2 w-full px-2">
                <Select
                  name="industry"
                  label="Industry"
                  // onSearch={(text) => setSearch(text)}
                  control={control}
                  onChange={(e, onChange) => {
                    onChange(e.value);
                  }}
                  options={industryOptions}
                  rules={{
                    required: 'This field is required',
                  }}
                  //mode="multiple"
                  //showSearch
                  allowClear
                  placeholder="Choose a plan"
                  // valueLabel="role_name"
                  // optionLabel="role_name"
                />
              </div>
            </div>
          </div>
          <div class="px-8 w-full">
            <div class="flex -mx-2 md:flex-row flex-col">
              <div className="md:w-1/2 w-full px-2">
                <Select
                  allowClear
                  showArrow={false}
                  control={control}
                  options={CURRENCY_CODES}
                  name={'ctc_currency_code'}
                  label={<p className="mr-4">Code</p>}
                  rules={{
                    required: 'This field is required',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold">
          {messageContextHolder}
          <div className="flex items-center justify-between">
            <Button isCancel onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" className="ml-4" htmlType="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default CreateRegistration;
