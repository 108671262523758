import React, { useState, useRef, useEffect } from 'react';

const DateInput = ({ setSelectedKeys, setIsReset, isReset }) => {
  const [date, setDate] = useState({ day: '', month: '', year: '' });
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  console.log(isReset);
  useEffect(() => {
    if (date?.day && date?.month && date?.year) {
        // setSelectedKeys([date?.day + '-' + date?.month + '-' + date?.year]);
        setSelectedKeys([date?.year + '-' + date?.month + '-' + date?.day]);
      }
  }, [date]);

  useEffect(() => {
    if (isReset) {
      setDate({ day: '', month: '', year: '' });
    }
  }, [isReset]);

  const handleChange = (field, value, maxLength, nextRef) => {
    // Only allow numbers
    if (!/^\d*$/.test(value)) return;
    // Update the field value
    setDate((prev) => ({ ...prev, [field]: value }));
    // Auto-advance to next field if max length reached
    if (value.length === maxLength && nextRef) {
      nextRef.current.focus();
    }
  };
  console.log(date, 'datedatedate');
  return (
    <div className="flex items-center space-x-2 mb-2">
      <input
        type="text"
        value={date.day}
        onChange={(e) => handleChange('day', e.target.value, 2, monthRef)}
        placeholder="DD"
        maxLength={2}
        className="w-16 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
        aria-label="Day"
      />
      <span className="text-gray-500">-</span>
      <input
        ref={monthRef}
        type="text"
        value={date.month}
        onChange={(e) => handleChange('month', e.target.value, 2, yearRef)}
        placeholder="MM"
        maxLength={2}
        className="w-16 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
        aria-label="Month"
      />
      <span className="text-gray-500">-</span>
      <input
        ref={yearRef}
        type="text"
        value={date.year}
        onChange={(e) => handleChange('year', e.target.value, 4, null)}
        placeholder="YYYY"
        maxLength={4}
        className="w-28 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
        aria-label="Year"
      />
    </div>
  );
};

export default DateInput;
