import React, { useState, useEffect, useMemo, useRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  PlusOutlined,
  EditTwoTone,
  EditOutlined,
  DeleteOutlined,
  DeleteTwoTone,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import AddEvaluationForm from './AddEvaluationForm';
import settingService from '../../Services/settingsService';
import { AiFillYahoo } from 'react-icons/ai';
import toast from 'react-hot-toast';
import AddPrescreenForm from './AddPrescreenParameter';
import DataGridLayout, {
  useDataGridLayout,
} from 'Components/CommonComponents/DataGridLayout';
import { PERMISSION_TYPES } from 'Utilities/constants';
import WithAuthentication from 'Components/HOC/WithAuthentication';
import Button from 'Components/CommonComponents/Button/Button';
import { debounce, isEmpty } from 'lodash';
import { useLayoutStore } from 'Store/LayoutStore';
import { useGetPrescreenParameter } from 'ServiceHooks/settingsHooks';
import { Tooltip } from 'antd';
import { AiFillEdit } from 'react-icons/ai';
import { IoMdTrash } from 'react-icons/io';
import AntdTable from 'Components/CommonComponents/AntdTable/AntdTable';
import { TableActionsIcon } from 'Components/CommonComponents/Icons';
import ActionsDropdown from 'Components/CommonComponents/ActionsDropdown';

const tableColumn = [
  {
    fieldID: 'Name',
    fieldLable: 'Name',
    fieldShow: false,
  },
  {
    fieldID: 'CreatedDate',
    fieldLable: 'Created Date',
    fieldShow: true,
  },
];

const PrescreenParameter = () => {
  const currentStatus = 'PRESCREEN_PARAMETER';
  const navigate = useNavigate();
  //const [listView, setListView] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [parameterId, setParameterId] = useState();
  const listView = localStorage.getItem('listView');
  const [isListView, setListView] = useState('true');
  const [parameterDta, setParameterData] = useState();

  const [gridColumn, setGridColumn] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [sorting, setSorting] = useState('');
  const [rowInstance, setRowInstance] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [offset, setOffset] = useState(0);
  const [hoveredRow, setHoveredRow] = useState(null);
  // const screenRef = useRef(null);
  const [screenRef, setScreenRef] = useState(null)

  const { column_preferece, setGridColumnPreference } = useLayoutStore(
    (state) => ({
      column_preferece: state.column_preferece,
      setGridColumnPreference: state.setGridColumnPreference,
    }),
  );

  useEffect(() => {
    if (!isEmpty(column_preferece?.SETTINGS[currentStatus])) {
      setGridColumn(column_preferece?.SETTINGS[currentStatus]);
    }
  }, [column_preferece]);

  const {
    data: prescreenParameterList,
    isFetching: isPrescreeningParameterFetching,
    isLoading: isLoadingPrescreenParameter,
    refetch: refetchPrescreenParameter,
  } = useGetPrescreenParameter({
    filter,
    sorting,
    params: {
      search,
      limit: pageSize,
      offset: offset,
      order_by: sorting,
    },
  });

  const {
    apiRef: apiRef2,
    paginationModel,
    setPaginationModel,
    handlePaginationModalChange,
    handleRowSelectionModelChange,
    rowSelectionModel,
    calculatePaginationData,
  } = useDataGridLayout();

  // Pagination
  const [companyListResponse, setCompanyListResponse] = useState();

  const onListViewClick = () => {
    // console.log("listview");
    setListView('true');
    localStorage.setItem('listView', 'true');
    document.getElementById('card_view').style.backgroundColor = '';
    document.getElementById('card_view').style.color = '#0c2556';
    document.getElementById('list_view').style.backgroundColor = '#0c2556';
    document.getElementById('list_view').style.color = '#fff';
  };

  const onCardViewClick = () => {
    // console.log("cardView");
    setListView('false');
    localStorage.setItem('listView', 'false');
    document.getElementById('list_view').style.backgroundColor = '';
    document.getElementById('list_view').style.color = '#0c2556';
    document.getElementById('card_view').style.backgroundColor = '#0c2556';
    document.getElementById('card_view').style.color = '#fff';
  };

  const grid_columns = useMemo(() => {
    const updatedColumns = gridColumn?.map((column) => {
      let updatedColumn = { ...column };

      if (column?.dataIndex === 'actions') {
        updatedColumn = {
          ...updatedColumn,
          width: 80,
          title: TableActionsIcon,
          render: (_, record, index) => {
            if (index === hoveredRow) {
              return (
                <ActionsDropdown
                  containerType={() => screenRef?.current}
                  items={[
                    {
                      key: '0',
                      label: (
                        <Tooltip
                          title="Edit Prescreen Parameter"
                          placement="leftBottom"
                        >
                          <Button
                            type="text"
                            onClick={() => handleEdit(record.id)}
                          >
                            <AiFillEdit
                              size={'1rem'}
                              className="text-cyan-700"
                            />
                          </Button>
                        </Tooltip>
                      ),
                    },
                    {
                      key: '1',
                      label: (
                        <Tooltip
                          title="Delete Prescreen Parameter"
                          placement="leftBottom"
                        >
                          <Button
                            type="text"
                            onClick={() => handleDelete(record?.id)}
                          >
                            <IoMdTrash
                              size={'1rem'}
                              className="text-cyan-700"
                            />
                          </Button>
                        </Tooltip>
                      ),
                    },
                  ]}
                />
              );
            } else {
              return null;
            }
          },
        };
      }
      // Add additional conditional rendering for other columns here if needed

      return updatedColumn;
    });

    return updatedColumns;
  }, [gridColumn, hoveredRow]);

  const handleSelectedRow = (record) => {
    console.log('reord', record);
    setRowInstance(record);
  };

  const tableList = () => {
    return (
      <div className="mt-spacing1 w-full max-h-[calc(90vh-9.5rem)] overflow-y-auto scrollbar-none">
        <AntdTable
          screenRef={screenRef}
          setScreenRef={setScreenRef}
          grid_columns={grid_columns}
          setGridColumn={setGridColumn}
          data={prescreenParameterList?.results}
          totalItems={prescreenParameterList?.count}
          table_name={currentStatus}
          preference="SETTINGS"
          setGridColumnPreference={setGridColumnPreference}
          column_preferece={column_preferece}
          setPageSize={setPageSize}
          setOffset={setOffset}
          pageSize={pageSize}
          offset={offset}
          setSearch={setSearch}
          search={search}
          setFilter={setFilter}
          setSorting={setSorting}
          loading={
            isPrescreeningParameterFetching || isLoadingPrescreenParameter
          }
          handleRowClick={handleSelectedRow}
          setHoveredRow={setHoveredRow}
        />
      </div>

      // <div className="scheduleList w-full max-h-[calc(90vh-8rem)] overflow-y-auto scrollbar-none">
      //   <DataGridLayout
      //     column={arrColumn}
      //     row={arrRow}
      //     apiRef={apiRef2}
      //     showCustomPagination
      //     paginationModel={paginationModel}
      //     rowSelectionModel={rowSelectionModel}
      //     onPaginationModelChange={(model, details) =>
      //       handlePaginationModalChange(model, details)
      //     }
      //     onRowSelectionModelChange={(newRowSelectionModel) => {
      //       handleRowSelectionModelChange(
      //         newRowSelectionModel,
      //         arrRowData?.data,
      //         newRowSelectionModel,
      //       );
      //     }}
      //     getRowClassName={(params) =>
      //       getCandidatesTableRowClassName(params)
      //     }
      //     // loading={getSettingsDepartmentsData()}
      //     results={arrRowData?.data}
      //     useNewPagination
      //     onSearchDataGrid={
      //       //   (value) => {
      //       //   console.log("onSearchDataGrid", value);
      //       //   getJobListSearch(
      //       //     value
      //       //   );
      //       // }
      //       debounce((value) => {
      //         console.log('onSearchDataGrid', value);
      //         getEvalutionInfo(value);
      //       }, 500)
      //     }
      //     onFilterModelChange={(e, type) => {
      //       console.log('e', e, 'type', type);
      //       if (e.items.length > 0) {
      //         var { field, value, operator } = e?.items[0];
      //         if (value !== undefined) {
      //           if (operator === 'equals') {
      //             console.log(field, value, operator);
      //             operator = 'exact';

      //             var finalvalue = `${param}__i${operator}=${value}`;
      //             console.log('finalvalue', finalvalue);
      //             getEvalutionColumnInfo(finalvalue);
      //           } else if (
      //             operator === 'startsWith' ||
      //             operator === 'endsWith'
      //           ) {
      //             operator = operator.toLowerCase();
      //             console.log('operator', operator);

      //             var finalvalue = `${param}__i${operator}=${value}`;
      //             console.log('finalvalue', finalvalue);
      //             getEvalutionColumnInfo(finalvalue);
      //           } else {

      //             var finalvalues = `${params}__i${operator}=${value}`;
      //             console.log('finalvalue', finalvalue);
      //             getEvalutionColumnInfo(finalvalues);
      //           }
      //         }
      //       } else if (e?.quickFilterValues?.length === 0) {
      //         getEvalutionInfo('');
      //       } else if (type.reason === 'deleteFilterItem') {
      //         getEvalutionColumnInfo('');
      //       }
      //     }}
      //     showQuickFilterOption={true}
      //     disableColumnSelectorMenu
      //   />
      // </div>
    );
  };
  const truncateStrHandler = (str) => {
    return str.length > 35 ? str.substring(0, 28) + '...' : str;
  };

  const handleEdit = (id) => {
    setIsAdd(true);
    setParameterId(id);
  };
  const getCandidatesTableRowClassName = (params) => {
    if (params) {
      return params.indexRelativeToCurrentPage % 2 === 0
        ? 'Mui-even-row'
        : 'Mui-odd-row';
    }
  };
  const handelBack = () => {
    navigate('/hr/settings');
  };
  const handleAdd = () => {
    setIsAdd(true);
    setParameterId('');
  };
  useEffect(() => {
    // getEvalutionInfo();
    refetchPrescreenParameter();
  }, [isAdd, isEdit, paginationModel]);

  const handleDelete = async (id) => {
    await settingService
      .deletePrescreenParameter(id)
      .then((res) => {
        console.log(res);
        //   toast.success("Success");
        // getEvalutionInfo();
        refetchPrescreenParameter();
        toast.success('Successfully deleted item..!!');
        // props.close(false);
      })
      .catch((error) => {
        // getEvalutionInfo();
        refetchPrescreenParameter();
        toast.error('Something went wrong..!!');
      });
  };

  const registeredCompanyCardView = (data) => {
    return (
      <div>
        <div className="p-4 m-3 w-36 lg:w-72 overflow-hidden primary-box-shadow rounded-2xl justify-center flex-col md:flex-row lg:flex-row cursor-pointer transition ease-in-out delay-90 hover:-translate-y-1 hover:scale-80 hover:bg-orange-200 duration-300">
          <div className="flex flex-row flex-wrap justify-center pl-2 border-b-gray-200 border-b">
            <div>
              <p className="text-xs sm:text-sm font-semibold mb-1">
                {truncateStrHandler(data.name)}
              </p>
            </div>
          </div>
          <div class="px-6 pt-4 pb-2 text-center">
            <span
              class="inline-block border-solid border-2 border-sky-500 rounded-full px-3 py-1 text-sm font-semibold mr-3 mb-2 transition ease-in-out delay-90 hover:text-black hover:bg-slate-200 hover:-translate-y-1 hover:scale-80 duration-300"
              title="Edit"
              // onClick={() => handleEdit(data.id)}
            >
              <EditOutlined onClick={() => handleEdit(data.id)} />
            </span>
            <span
              class="inline-block border-solid border-2 border-sky-500 rounded-full px-3 py-1 text-sm font-semibold mr-3 mb-2 transition ease-in-out delay-90 hover:text-black hover:bg-slate-200 hover:-translate-y-1 hover:scale-80 duration-300"
              title="Delete"
              onClick={() => handleDelete(data.id)}
            >
              <DeleteOutlined
              // onClick={() => handleDelete(data.id)}
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bg-[#F0F6F6]">
        <div
          className="flex items-center cursor-pointer"
          type="text"
          onClick={handelBack}
        >
          {!isAdd && (
            <div>
              <ArrowBackIcon />
              <span className="ml-4 text-base font-medium">Settings</span>
            </div>
          )}
        </div>
        {!isAdd && (
          <div className="flex flex-wrap items-center mb-4 w-full h-fit">
            <div className="w-1/2">
              <h3 className="text-base font-medium mt-4">
                Prescreen Parameters
              </h3>
            </div>
            <div className="w-1/2 flex flex-wrap justify-end items-center">
              <span className="text-end">
                <Button type={'primary'} onClick={() => handleAdd()}>
                  Add
                </Button>
              </span>
            </div>
          </div>
        )}

        <div className="flex flex-wrap">
          {isEdit === true || isAdd === true ? (
            <AddPrescreenForm
              close={setIsAdd}
              editClose={setIsEdit}
              parameterId={parameterId}
              title={'Edit prescreen Parameter'}
            />
          ) : isListView === 'true' ? (
            <>{tableList()}</>
          ) : (
            companyListResponse &&
            companyListResponse?.results.map((items) => {
              return registeredCompanyCardView(items);
            })
          )}
        </div>
      </div>
    </>
  );
};

export default WithAuthentication(
  PERMISSION_TYPES.VIEW_PRESCREEN_PARAMETER_TAB,
)(PrescreenParameter);
