import React from 'react';
import { Radio as AntRadio, Form } from 'antd';
import './RadioGroup.scss';
import { useController } from 'react-hook-form';

const RadioGroup = ({
  options,
  disabledArray = [],
  onChange: customOnChange = null,
  withCustomContent = false,
  nonLoopContent = null,
  containerRef = null,
  childRef = null,
  radioGroupClassName = '',
  // hook form specific
  name,
  control,
  defaultValue,
  rules = {},
  label,
  readOnly,
  isChangeLabelColor = false,
  ...others
}) => {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return withCustomContent ? (
    <Form.Item
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
    >
      <AntRadio.Group
        className={`radioGroup withCustomContent ${radioGroupClassName}`}
        onChange={(e) => {
          if (customOnChange) {
            customOnChange(e, onChange);
          } else {
            onChange(e);
          }
        }}
        value={value}
        ref={ref}
        {...others}
      >
        <div className="loopWrapper">
          {options.map((item) => {
            return (
              <div className="radioItemsWrapper" ref={childRef}>
                <AntRadio
                  key={item.value}
                  value={item.value}
                  disabled={disabledArray.includes(item.value)}
                  className={`${isChangeLabelColor && item?.className}`}
                >
                  {item.label}
                </AntRadio>
                {item.customContent}
              </div>
            );
          })}
        </div>
        {nonLoopContent && (
          <div class="nonLoopWrapper">{nonLoopContent}</div>
        )}
      </AntRadio.Group>
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
    >
      <AntRadio.Group
        onChange={(e) => {
          customOnChange ? customOnChange(e, onChange) : onChange(e);
        }}
        value={value}
        {...others}
      >
        {options.map((item) => {
          return (
            <AntRadio
              key={item.value}
              value={item.value}
              //disabled={disabledArray.includes(item.value)}
              disabled={readOnly}
            >
              {item.label}
            </AntRadio>
          );
        })}
      </AntRadio.Group>
    </Form.Item>
  );
};

export default RadioGroup;
