import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  memo,
} from 'react';
import {
  useSensor,
  DndContext,
  useSensors,
  DragOverlay,
  PointerSensor,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  useSortable,
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { useFullscreen, useToggle } from 'react-use';
import { Table, Button, Popover, Tooltip } from 'antd';
import { Resizable } from 'react-resizable';

import {
  column,
  column_active,
  maximize,
  minimize,
  minimize_active,
} from '../Icons';
import Empty from '../Empty/Empty';
import '../../../Styles/AntdGrid.scss';
import SearchInput from './SearchInput';
import 'react-resizable/css/styles.css';
import ColumnPreference from './ColumnPreference';
import { UpdatePersistedStore, getColumnSearchProps } from './constants';
import { areColumnsEqual } from 'Utilities/constants';
import CustomPagination from './CustomPagination';
import { render } from '@fullcalendar/core/preact';

const DragIndexContext = createContext({
  active: -1,
  over: -1,
});
const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  // drag active style
  let style = {};
  if (active && active === id) {
    style = {
      backgroundColor: 'gray',
      opacity: 0.5,
    };
  }
  // dragover dashed style
  else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? {
            borderRight: '1px dashed gray',
          }
        : {
            borderLeft: '1px dashed gray',
          };
  }
  return style;
};
const TableBodyCell = (props) => {
  const dragState = useContext(DragIndexContext);
  return (
    <td
      {...props}
      style={{
        ...props.style,
        ...dragActiveStyle(dragState, props.id),
      }}
    />
  );
};

const TableHeaderCell = (props) => {
  //resize integration
  const { id, onResize, width, ...restProps } = props;
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
  });
  const [isResizing, setIsResizing] = useState(false);
  const style = {
    ...props.style,
    cursor: isResizing ? 'col-resize' : 'move',
    ...(isDragging && !isResizing
      ? {
          position: 'relative',
          zIndex: 9999,
          userSelect: 'none',
        }
      : {}),
    ...dragActiveStyle(dragState, props.id),
  };

  const handleResizeStart = () => {
    setIsResizing(true); // Start resizing
  };

  const handleResizeStop = () => {
    setIsResizing(false); // Stop resizing
  };

  if (!width) {
    return (
      <th
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      />
    );
  }

  return (
    <Resizable
      width={width}
      height={0} // height is not necessary for horizontal resizing
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => e.stopPropagation()}
        />
      } // Drag handle
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        onMouseDown={(e) => {
          if (isResizing) {
            e.stopPropagation(); // Prevent dragging if resizing
          }
        }}
      />
    </Resizable>
  );
};

const AntdTable = ({
  grid_columns = [],
  setGridColumn = () => {},
  data = [],
  totalItems = 0,
  allowFullscreen = true,
  setPageSize = () => {},
  setOffset = () => {},
  pageSize = 25,
  offset = 0,
  setSearch = () => {},
  search = '',
  setFilter = () => {},
  setMultiFilter = () => {},
  loading = false,
  table_name = '',
  preference = '',
  handleDoubleClick = () => {
    null;
  },
  handleRowClick = () => {
    null;
  },
  setHoveredRow = () => {
    null;
  },
  hoveredRow = null,
  heightLimit = window.innerHeight * 0.5,
  screenRef = null,
  addedRowClassNames = (record) => '',
  setGridColumnPreference = () => {},
  column_preferece = [],
  filterList = [],
  setSorting = () => {},
  isRowSelection = false,
  setRowSelectedID = () => {},
  setRowSelectedItems = () => {},
  headerTopActions = () => {
    null;
  },
  isResetSearch = false,
  setResetSearch = () => {},
  rowSelectedIds = [],
  setMinScreen = () => {},
  isMinScreen = false,
  isPreventMouseLeave = false,
  position = 'bottomRight',
  defaultPagination = true,
  setScreenRef = () => {},
  columnUpdated = false,
  setColumnUpdated = () => {},
}) => {
  const tableRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [open, setOpen] = useState(false);
  const [isColResize, setColResize] = useState(false);
  const [isColSwap, setColSwap] = useState(false);
  const [isPreferenceChange, setPreferenceChange] = useState(false);
  const [columnsState, setColumnsState] = useState([]);
  const [dragIndex, setDragIndex] = useState({
    active: -1,
    over: -1,
  });

  const tableScreenRef = useRef(null);
  const currentScreenRef = Boolean(screenRef) ? screenRef : tableScreenRef;
  const [currentScreenType, toggleTableScreen] = useToggle(false);
  const isFullscreen = useFullscreen(tableScreenRef, currentScreenType, {
    onClose: () => toggleTableScreen(false),
  });

  const [openColumnKey, setOpenColumnKey] = useState(null);
  const listCount = data?.length;
  const [sortOrder, setSortOrder] = useState(null);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (columnUpdated) {
      setColumnsState(() => []);
      setColumnUpdated(false);
    }
  }, [columnUpdated]);

  useEffect(() => {
    if (tableScreenRef) {
      setScreenRef(tableScreenRef);
    }
  }, [isFullscreen]);

  useEffect(() => {
    if (!columnsState?.length) {
      setColumnsState(() => grid_columns);
    } else {
      if (!areColumnsEqual(grid_columns, columnsState)) {

        if (isColResize || isColSwap || isPreferenceChange) {
          setColSwap(false);
          setPreferenceChange(false);
          setGridColumn(() => columnsState);
          const updated_preferences = UpdatePersistedStore(
            column_preferece,
            columnsState,
            table_name,
            preference,
          );
          setGridColumnPreference(updated_preferences);
        } else {
          const gridAction = grid_columns?.filter(
            (grid) => grid?.dataIndex === 'actions',
          )?.[0];
          const colActions = columnsState?.filter(
            (col) => col?.dataIndex === 'actions',
          )?.[0];
          // setColumnsState((prev)=> grid_columns);
          if (gridAction !== colActions) {
            setColumnsState((prevState) =>
              prevState.map((item) =>
                item.dataIndex === 'actions' ? gridAction : item,
              ),
            );
          }
          // if (gridAction) {
          //   setColumnsState((prevState) =>
          //     prevState.map((item) =>
          //       item.dataIndex === 'actions' ? gridAction : item,
          //     ),
          //   );
          // }
          // setColumnsState(columnsState);
        }
      }
    }
  }, [columnsState, grid_columns]);


  useEffect(() => {
    if (isMinScreen) {
      toggleTableScreen(false);
      setMinScreen(false);
    }
  }, [isMinScreen]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (tableRef.current) {
        const table = tableRef.current;
        const scrollContainer = table.querySelector('.ant-table-body');

        if (scrollContainer) {
          const scrollAmount = 30;
          switch (e.key) {
            case 'ArrowUp':
              scrollContainer.scrollTop -= scrollAmount;
              break;
            case 'ArrowDown':
              scrollContainer.scrollTop += scrollAmount;
              break;
            case 'ArrowLeft':
              scrollContainer.scrollLeft -= scrollAmount;
              break;
            case 'ArrowRight':
              scrollContainer.scrollLeft += scrollAmount;
              break;
            default:
              break;
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setIsReset(false);
    confirm();
    setSearchedColumn(dataIndex);
    let filterVal = dataIndex + '=' + selectedKeys[0];
    if (dataIndex === 'experience' && selectedKeys?.length) {
      let newFilters = [];
      if (selectedKeys[0]) {
        filterVal = 'minimum_experience' + '=' + selectedKeys[0];
        newFilters.push(filterVal);
        // setFilter((prev) => [...prev, filterVal]);
      }
      if (selectedKeys?.length == 2) {
        if (selectedKeys[1]) {
          filterVal = 'maximum_experience' + '=' + selectedKeys[1];
          newFilters.push(filterVal);
          // setFilter((prev) => [...prev, filterVal]);
        }
      }
      setFilter((prev) => [...prev, ...newFilters]);
    }
    //  else {
    //   if (selectedKeys?.length) {
    //     setFilter((prev)=> [...prev, filterVal]);
    //   }
    // }
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    // setSearchText('');
    // setFilter('');
    setMultiFilter('');
    confirm();
  };

  const handleResize = useCallback(
    (index) =>
      (e, { size }) => {
        setColumnsState((prevColumns) => {
          const newColumns = [...prevColumns];
          newColumns[index] = {
            ...newColumns[index],
            width: size.width,
          };
          return newColumns;
        });
      },
    [],
  );

  const getSortTooltipText = (order) => {
    if (order === 'ascend') return 'Click to cancel sorting';
    if (order === 'descend') return 'Click to sort ascending';
    return 'Click to sort descending';
  };

  const updated_grid_columns = useMemo(() => {
    const newColumns = columnsState?.map((column, i) => {
      let newCol = { ...column, key: `${i}` };
      if (column?.is_search) {
        const searchProps = getColumnSearchProps(
          column?.dataIndex,
          searchInput,
          handleSearch,
          searchedColumn,
          search,
          handleReset,
          column?.title,
          setIsReset,
          isReset,
          column?.colType,
        );
        newCol = {
          ...newCol,
          filterDropdown: searchProps?.filterDropdown,
          filterIcon: searchProps?.filterIcon,
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setOpenColumnKey(column.key);
            } else if (openColumnKey === column.key) {
              setOpenColumnKey(null);
            }
          },
          filterDropdownOpen: openColumnKey === column.key,
          getPopupContainer: (triggerNode) =>
            triggerNode.closest('.datagrid-container'),
          // render: searchProps.render,
          render: (text, record, index) => {
            const searchRender = searchProps.render
              ? searchProps.render(text, record, index)
              : text;
            return column.render
              ? column.render(text, record, index)
              : searchRender;
          },
        };
      } else if (column?.is_filter) {
        let filters = filterList;
        if (Object.hasOwn(column, 'filters')) {
          filters = column?.filters;
        }
        if (column.dataIndex === 'interviews') {
          filters = filterList;
        }
        newCol = {
          ...newCol,
          filters: filters,
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setOpenColumnKey(column.key);
            } else if (openColumnKey === column.key) {
              setOpenColumnKey(null);
            }
          },
          filterDropdownOpen: openColumnKey === column.key,
        };
      }

      if (column?.is_sort) {
        newCol = {
          ...newCol,
          sorter: (a, b, sortOrder) => {},
          sortDirections: ['descend', 'ascend'],
          // title: (
          //   <Tooltip
          //     title={getSortTooltipText(sortOrder)}
          //     getPopupContainer={() => currentScreenRef?.current}
          //   >
          //     <span>{newCol?.title}</span>
          //   </Tooltip>
          // ),
        };
      }

      return {
        ...newCol,
        onHeaderCell: (col) => ({
          id: `${i}`,
          width: col.width,
          onResize: (e, { size }) => {
            setColumnsState((prevColumns) => {
              const updatedColumns = [...prevColumns];
              if (updatedColumns[i].width !== size.width) {
                updatedColumns[i] = {
                  ...updatedColumns[i],
                  width: size.width,
                };
                return updatedColumns;
              }
              return prevColumns;
            });
            setColResize(true);
          },
        }),
        // onHeaderCell: (col) => ({
        //   id: `${i}`,
        //   width: col.width,
        //   // onResize: handleResize(i),
        //   onResize: (e, { size }) => {
        //     console.log(size.width,"size.width");
        //     newCol.width = size.width;
        //     setWidthChangeTrigger((prev) => prev + 1);
        //   },
        // }),
        onCell: () => ({
          id: `${i}`,
        }),
      };
    });
    return newColumns;
  }, [columnsState, openColumnKey, sortOrder, filterList]);

  const expandedRowRender = (record, index) => {
    const columns = updated_grid_columns?.filter(
      (c) => c?.isExpanded && c?.fieldShow,
    );

    return (
      <Table
        columns={columns}
        dataSource={[record]}
        pagination={false}
        scroll={{
          x: 1300,
        }}
        rowKey={(record) => record.id}
        className="customScrollbar"
        bordered={true}
      />
    );
  };

  const onChange = (pagination, filters, sorter, extra) => {
    setFilter([]);
    Object.keys(filters).forEach((column) => {
      const selectedValues = filters[column];
      if (selectedValues && selectedValues.length > 0) {
        let dataIndex = updated_grid_columns[column]?.dataIndex;
        if (dataIndex !== 'experience') {
          const is_filter = updated_grid_columns[column]?.is_filter;
          let filterPhrase = `${dataIndex}=${JSON.stringify(selectedValues)}`;
          if (!is_filter) {
            filterPhrase = `${dataIndex}=${selectedValues?.[0]}`;
          }
          setFilter((prev) => [...prev, filterPhrase]);
        }
      }
    });
    setSortOrder(sorter.order);
    let sortVal = '';
    if (sorter?.order === 'descend') {
      sortVal = [`-${sorter?.column?.dataIndex}`];
    } else if (sorter?.order === 'ascend') {
      sortVal = [sorter?.column?.dataIndex];
    }
    setSorting(sortVal);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  // -----------------------------------------dragable
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }) => {
    const activeIndex = updated_grid_columns.findIndex(
      (i) => i.key === active?.id,
    );
    if (!updated_grid_columns[activeIndex]?.fixed) {
      const overIndex = updated_grid_columns.findIndex(
        (i) => i.key === over?.id,
      );
      if (!isColResize && updated_grid_columns[overIndex]?.fixed !== "right") {
        if (active.id !== over?.id) {
          setColumnsState((prevState) => {
            const updated_preferences = UpdatePersistedStore(
              column_preferece,
              arrayMove(updated_grid_columns, activeIndex, overIndex),
              table_name,
              preference,
            );

            setGridColumnPreference(updated_preferences);
            return arrayMove(updated_grid_columns, activeIndex, overIndex);
          });
        }
      }
      setColResize(false);
      setDragIndex({
        active: -1,
        over: -1,
      });
    }
  };
  const onDragOver = ({ active, over }) => {
    setColSwap(true);
    const activeIndex = updated_grid_columns.findIndex(
      (i) => i.key === active.id,
    );
    if (!updated_grid_columns[activeIndex]?.fixed) {
      const overIndex = updated_grid_columns.findIndex(
        (i) => i.key === over?.id,
      );
      setDragIndex({
        active: active.id,
        over: over?.id,
        direction: overIndex > activeIndex ? 'right' : 'left',
      });
    }
  };

  const getRowClassName = (record, index) => {
    let names = `${addedRowClassNames(record)} `;

    const avoidedNames = names.includes('candidates.conflicted');
    if (!avoidedNames && index % 2 === 0) {
      names += 'row-even-bgColor';
    }

    return names;
  };

  const rowSelection = {
    selectedRowKeys: rowSelectedIds,
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelectedID((prev) => selectedRowKeys);
      setRowSelectedItems((prev) => selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    preserveSelectedRowKeys: true,
  };

  const handleSelectionRow = (record) => {
    handleRowClick(record);
    if (isRowSelection) {
      const selectedKeys = [...rowSelectedIds];
      const selectedIndex = selectedKeys.indexOf(record.id);
      if (selectedIndex >= 0) {
        selectedKeys.splice(selectedIndex, 1);
      } else {
        selectedKeys.push(record.id);
      }
      setRowSelectedID(selectedKeys);
      const selectedItems = data.filter((item) =>
        selectedKeys.includes(item.id),
      );
      setRowSelectedItems(selectedItems);
    }
  };

  const expandable = {
    expandedRowRender,
    defaultExpandedRowKeys: [],
  };

  const tableProps = {
    bordered: true,
    loading: loading,
    // expandable,
  };

  const transformedColumns = useMemo(() => {
    return updated_grid_columns
      ?.filter((c) => !c?.isExpanded && c?.fieldShow)
      ?.map((col) => {
        if (col.is_sort) {
          return {
            ...col,
            title: (
              <Tooltip
                title={getSortTooltipText(sortOrder)}
                getPopupContainer={() => tableScreenRef?.current}
              >
                <span>{col.title}</span>
              </Tooltip>
            ),
          };
        }
        return col;
      });
  }, [updated_grid_columns, tableScreenRef]);

  return (
    <>
      <div
        ref={tableScreenRef}
        className={`datagrid-container bg-[#fff] p-5 ${isFullscreen ? 'toggled-full-screen flex flex-col justify-center h-full' : ''}`}
      >
        <div className="mb-4 flex items-center justify-between">
          <SearchInput setSearch={setSearch} search={search} />
          {/* {defaultPagination === false &&
          <CustomPagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} setOffset={setOffset} totalItems={totalItems} position={position} />
          } */}
          <div className="flex items-center gap-5">
            {headerTopActions()}
            <div className="flex items-center space-x-4">
              <Popover
                content={
                  <ColumnPreference
                    grid_columns={updated_grid_columns}
                    setGridColumn={setColumnsState}
                    table_name={table_name}
                    preference={preference}
                    setPreferenceChange={setPreferenceChange}
                  />
                }
                open={open}
                trigger="click"
                placement="bottomRight"
                title="Column Preferences"
                onOpenChange={handleOpenChange}
                getPopupContainer={() => tableScreenRef?.current}
                overlayClassName="custom-popover"
              >
                <Popover
                  placement="bottom"
                  content={<div>Column Preferences</div>}
                  getPopupContainer={() => tableScreenRef?.current}
                >
                  <Button className="bg-white">
                    <img
                      src={open ? column_active : column}
                      className="min-h-[0.75rem]"
                    />
                  </Button>
                </Popover>
              </Popover>
              {allowFullscreen && (
                <Popover
                  placement="bottom"
                  getPopupContainer={() => tableScreenRef?.current}
                  content={
                    <div>{isFullscreen ? 'Minimize' : 'Maximize'}</div>
                  }
                >
                  <Button
                    className="bg-white"
                    onClick={() => toggleTableScreen()}
                  >
                    <img
                      src={isFullscreen ? minimize_active : maximize}
                      className="min-h-[0.75rem]"
                    />
                  </Button>
                </Popover>
              )}
            </div>
          </div>
        </div>
        <div
          ref={tableRef}
          className="max-h-[calc(90vh-9.5rem)] custom-table-head"
        >
          <DndContext
            sensors={sensors}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            collisionDetection={closestCenter}
          >
            <SortableContext
              items={updated_grid_columns?.map((i) => i?.key)}
              strategy={horizontalListSortingStrategy}
            >
              <DragIndexContext.Provider value={dragIndex}>
                <Table
                  {...tableProps}
                  // columns={updated_grid_columns?.filter(
                  //   (c) => !c?.isExpanded && c?.fieldShow,
                  // )}
                  columns={transformedColumns}
                  getPopupContainer={() => tableScreenRef?.current}
                  dataSource={data}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalItems,
                    showSizeChanger: true,
                    pageSizeOptions: ['25', '50', '100'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} out of ${total}`,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                      setOffset(pageSize * (page - 1));
                    },
                    position: [position],
                  }}
                  scroll={{
                    x: 1200,
                    y: isFullscreen ? '65vh' : heightLimit,
                  }}
                  className="customScrollbar custom-table"
                  onChange={onChange}
                  rowKey={(record) => record.id}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleSelectionRow(record);
                      },
                      onDoubleClick: (event) => {
                        handleDoubleClick(record);
                      },
                      onMouseEnter: () => setHoveredRow(rowIndex),
                      onMouseLeave: () =>
                        isPreventMouseLeave ? null : setHoveredRow(null),
                      style: { cursor: 'pointer' },
                    };
                  }}
                  components={{
                    header: {
                      cell: TableHeaderCell,
                    },
                    body: {
                      cell: TableBodyCell,
                    },
                  }}
                  sticky
                  rowClassName={getRowClassName}
                  rowSelection={
                    isRowSelection
                      ? {
                          type: 'checkbox',
                          ...rowSelection,
                          preserveSelectedRowKeys: true,
                        }
                      : false
                  }
                  showSorterTooltip={false}
                />
              </DragIndexContext.Provider>
            </SortableContext>
            <DragOverlay>
              <th
                style={{
                  backgroundColor: 'gray',
                  padding: 16,
                }}
              >
                {
                  updated_grid_columns[
                    updated_grid_columns?.findIndex(
                      (i) => i.key === dragIndex.active,
                    )
                  ]?.title
                }
              </th>
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </>
  );
};
export default memo(AntdTable);
